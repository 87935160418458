import React from 'react';
// import {Container} from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';


function OurGames() {
    return (
        <div className="OurGames">
            <h2 className="hpgTitle">OurGames</h2>
            {/*<p className="hpgText">We use bleeding edge technology to increase development speed and the quality of our products.</p>*/}
            <p> </p>
            <h2 className="hpgTitle">X-1</h2>
            <p className="hpgText">X-1 is a game-based assessment tool for analyzing personality attributes of a subject as relates to a workplace or potential job. It is designed to be a viable, low-cost, high-accuracy alternative to traditional resource-intensive assessment centers.</p>
            <p> </p>
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/X1_1.png"
                        alt="First slide"
                    />
                    {/*<Carousel.Caption>*/}
                    {/*    <h3>First slide label</h3>*/}
                    {/*    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                    {/*</Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/X1_2.png"
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/X1_3.png"
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/X1_4.png"
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/X1_5.png"
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/X1_6.png"
                        alt="Third slide"
                    />
                </Carousel.Item>
            </Carousel>


            <p> </p>
            <p className="px10TopMargin"> </p>
            <h2 className="hpgTitle">Cloud Breakers</h2>
            <p className="hpgText">The Cloud Breakers story centers on the player helping create a better world by aiding beleagured and isolated tribes utilizing their skills as a pilot. The player character's backstory is based on their own choices, allowing them to explore a narrative about their abandoned past life and meditate on their hopes and fears. Conversations take place over the radio with other pilots and radio operators from the villages. The player's demones are cathartically defeated as they help the indigenous villages flourish and the player decides they have atoned for their past mistakes. A secondary narrative layer that ties together player actions with the surrounding villages creates a cohesive multi-act structure that will expand into a myriad of potential outcomes. Though the game has no end, it has a satisfying narrative structure that makes the world feel personal to the player.
            </p>
            <h3 className="hpgTitle">Concept Art</h3>

            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/CB_CA/ConceptArt_07_Preview.jpg"
                        alt="First slide"
                    />
                    {/*<Carousel.Caption>*/}
                    {/*    <h3>First slide label</h3>*/}
                    {/*    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                    {/*</Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/CB_CA/ConceptArt_09_Preview.jpg"
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/CB_CA/ConceptArt_010_Preview.jpg"
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/CB_CA/ConceptArt_02_Preview.jpg"
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/CB_CA/ConceptArt_03_Preview.jpg"
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/CB_CA/ConceptArt_05_Preview.jpg"
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/CB_CA/ConceptArt_06_Preview.jpg"
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/CB_CA/ConceptArt_08_Preview.jpg"
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/CB_CA/ConceptArt_01_Preview.jpg"
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/CB_CA/ConceptArt_04_Preview.jpg"
                        alt="Third slide"
                    />
                </Carousel.Item>
            </Carousel>


            <p> </p>
            <h3 className="hpgTitle">Screenshots</h3>

            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/CB_SS/Screen1.png"
                        alt="First slide"
                    />
                    {/*<Carousel.Caption>*/}
                    {/*    <h3>First slide label</h3>*/}
                    {/*    <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>*/}
                    {/*</Carousel.Caption>*/}
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/CB_SS/Screen2.png"
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src="/img/CB_SS/Screen3.png"
                        alt="Third slide"
                    />
                </Carousel.Item>
            </Carousel>

            {/*<div className="hpgVideoContainer ratio ratio-16x9">*/}
            {/*    <iframe className="hpgVideoFrame" src="https://www.youtube-nocookie.com/embed/voHe3pifyIw?autoplay=1&controls=0&loop=1&mute=1&playlist=voHe3pifyIw" allowFullScreen></iframe>*/}
            {/*</div>*/}

            <p> </p>


        </div>
    );
}

export default OurGames;
