// import logo from './logo.svg';
import './App.css';
// import WorkDescription from "./modules/WorkDescription";
// import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import About from "./modules/About";
import Mankind from "./modules/Mankind";
import 'bootstrap/dist/css/bootstrap.min.css';
import WhatWeDo from "./modules/WhatWeDo";
import { Container } from 'react-bootstrap'
import OurGames from "./modules/OurGames";
import React from "react";


function App() {
  return (
      <div >
          <div className="header" >
                <div className="logo"> <img style={{ width: "5cm", height: "2.55144cm" }} src={"/img/HPG-Logo-H.jpg"}  alt="The logo of Hippie Panda Games containing a peeking panda." /> </div>
          </div>

          <Container>
              <div>
                  <About />
              </div>

              <div>
                  <Mankind />
              </div>

              <div>
                  <OurGames />
              </div>

              <div>
                  <WhatWeDo />
              </div>

              <div style={{ whiteSpace: 'pre-wrap' }}>
                  {`
                  
                  
               `}
              </div>
          </Container>

          {/*<Parallax pages={2}>*/}
          {/*    <ParallaxLayer offset={0} speed={0.00001} factor={1} style={{backgroundImage: "url(/img/PandaWebSiteBG.jpg)", backgroundSize: 'cover',}}>*/}

          {/*    </ParallaxLayer>*/}

          {/*    <ParallaxLayer offset={1} speed={1} factor={1} style={{ backgroundColor: "red", backgroundSize: 'cover',}}>*/}
          {/*        <WorkDescription />*/}
          {/*    </ParallaxLayer>*/}

          {/*</Parallax>*/}




          {/*<div className="App">*/}
          {/*  <header className="App-header">*/}
          {/*    <img src={logo} className="App-logo" alt="logo" />*/}
          {/*    <p>*/}
          {/*      Edit <code>src/App.jsx</code> and save to reload.*/}
          {/*    </p>*/}
          {/*            </header>*/}
          {/*</div>*/}




      </div>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.jsx</code> and save to reload.
    //     </p>
    //
    //   </header>
    // </div>
  );
}

export default App;
