import React from 'react';
// import {Container} from "react-bootstrap";
// import Carousel from 'react-bootstrap/Carousel';


function WhatWeDo() {
    return (
        <div className="WhatWeDo">
            <p className="px10TopMargin"> </p>
            <h2 className="hpgTitle">Other things we do</h2>
            <p className="hpgText">We use bleeding edge technology to increase development speed and the quality of our products.</p>
            <p> </p>
            <h2 className="hpgTitle">Photogrammetry</h2>
            <p className="hpgText">We use photogrammetry to speed up asset creation and guarantee 100% authenticity. We have the ability to create digital twins, be it of small objects or of entire landscapes.</p>
            <p> </p>
            <div className="hpgVideoContainer ratio ratio-16x9">
                <iframe className="hpgVideoFrame" src="https://www.youtube-nocookie.com/embed/voHe3pifyIw?autoplay=1&controls=1&loop=1&mute=1&playlist=voHe3pifyIw" allowFullScreen title="A video showing photogrammetry"></iframe>
            </div>
            <p className="px10TopMargin"> </p>
            <h2 className="hpgTitle">Advanced AI Image Generation</h2>
            <p className="hpgText">We leverage the power of AI Image generation to speed up ideation and the pre-production phases of our projects. Additionally we've used AI image generation to adjust characters for existing and upcoming projects. The ability to train our AI on specific people, characters or objects gives us the capability to place the intended target in any scenario we can dream of.</p>
            <p> </p>
            <div className="hpgVideoContainer ratio ratio-16x9">
                <iframe className="hpgVideoFrame" src="https://www.youtube-nocookie.com/embed/gBFHxQWvGQs?autoplay=1&controls=1&loop=1&mute=1&list=PLR7i-2da4jQJVAqV0XXDtfVDs50lvSfGm" allowFullScreen title="A video showing Advanced AI Image Generation"></iframe>
            </div>
            <p> </p>
            <p className="px10TopMargin"> </p>
            <h2 className="hpgTitle">Advanced AI motion transportation and postprocessing</h2>
            <p className="hpgText">We have the ability to bring nearly any 2D image to life. This technology has proved itself quite useful in game based psychometric tests we've developed.</p>
            <p> </p>
            <div className="hpgVideoContainer ratio ratio-16x9">
                <iframe className="hpgVideoFrame" src="https://www.youtube-nocookie.com/embed/lvICYTiGwr4?autoplay=1&controls=1&loop=1&mute=1&list=PLR7i-2da4jQK4BUmO3iISAV4dwE9eHKky" allowFullScreen title="A video showing Advanced AI motion transportation and postprocessing"></iframe>
            </div>
            <p className="px10TopMargin"> </p>
            <h2 className="hpgTitle">Digital humans</h2>
            <p className="hpgText">We take advantage of our photoscanning capabilities to create unique and tailored meta-humans.</p>
            <p> </p>
            <div className="hpgVideoContainer ratio ratio-16x9">
                <iframe className="hpgVideoFrame" src="https://www.youtube-nocookie.com/embed/fjbobG-FAX4?autoplay=1&controls=1&loop=1&mute=1&list=PLR7i-2da4jQKEwYaRu4peMaD-RaK-V2kL" allowFullScreen title="A video showing a digital human"></iframe>
            </div>
            <p className="px10TopMargin"> </p>
            <p className="px10TopMargin"> </p>
            <p> </p>


        </div>
    );
}

export default WhatWeDo;
