import React from 'react';

function Mankind() {
    return (
        <div className="mankind">
            <h2 className="hpgTitle">Games for mankind</h2>
            <p className="hpgText">Although our primary focus is on in-house games development we can also provide a multitude of services and support structures for your enterprise. Our secondary focus is on education, training, simulations and visualization.</p>
        </div>
    );
}

export default Mankind;
