import React from 'react';

function About() {
    return (
        <div className="about">
            <h2 className="hpgTitle">About</h2>
            <p className="hpgText">Hippie Panda Games was born out of a group of veteran game developers. Through collaboration on various game projects, we combined our 100+ years of cumulative experience in the industry to create things humanity needs.</p>
            <p className="hpgText">Our primary focus is on in-house games development with a secondary role in utilizing our skillsets to develop solutions for enterprise through gamification. We build strategies to assess and improve human performance leveraging emerging technologies.</p>
        </div>
    );
}

export default About;
